<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col>
        <b-card>
          <b-card-text>
            <DxGrid
              title="Job Relocations"
              url="/pages/services-tools/job-relocation/manage"
              :data-source="dataSource"
              :total-count="totalCount"
              @on-delete="(v) => onDelete(v)"
              @on-update="(v) => onUpdate(v)"
              @on-update-cancel="(v) => loadJobRelocations()"
              @on-row-click="(v) => onViewDetail(v)"
              @on-page-change="(v) => (page = v)"
              @on-size-change="(v) => (size = v)"
              @on-status-change="(v) => (status = v)"
              @on-search="(v) => (keyword = v)"
            >
              <template #columns>
                <DxColumn
                  v-for="(item, index) in dataColumns"
                  :key="index"
                  :data-field="item.field"
                  :caption="item.caption"
                  :format="item.format"
                  :data-type="item.dataType"
                />
              </template>
            </DxGrid>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import jobRelocationApi from '@api/job_relocations';
import { queryParameters } from '@/schema';
import { getUserData } from '@/auth/utils';

export default {
  name: 'JobRelocationPage',
  data: () => ({
    dataSource: [],
    dataColumns: [
      { caption: 'Reference No', field: 'refnum' },
      {
        caption: 'Start',
        field: 's_date',
        dataType: 'date',
        format: 'dd/MM/yyyy',
      },
      {
        caption: 'Start',
        field: 'e_date',
        dataType: 'date',
        format: 'dd/MM/yyyy',
      },
      { caption: 'Status', field: 'status' },
    ],
    totalCount: 0,
    page: 0,
    size: 0,
    status: 'O',
    keyword: null,
  }),
  computed: {
    getRole: {
      get() {
        const userData = getUserData();
        const { role } = userData;

        return role === 'admin';
      },
    },
  },
  watch: {
    page(v) {
      this.loadJobRelocations();
    },
    size(v) {
      if (v !== queryParameters.page.size) {
        this.loadJobRelocations();
      }
    },
    keyword(v) {
      this.loadJobRelocations();
    },
    status(v) {
      this.loadJobRelocations();
    },
  },
  mounted() {
    this.loadJobRelocations();
  },
  methods: {
    loadJobRelocations() {
      const params = {
        sort: '-updated_at',
        page: { size: this.size },
        status: this.status,
      };

      params.page.size = this.size;
      params.page.after = this.page;
      params.status = this.status;

      jobRelocationApi
        .list(params)
        .then(({ data, meta }) => {
          for (const item of data) {
            const { s_date, e_date } = item;
            if (s_date) {
              item.s_date = this.unixToDate(s_date);
            }

            if (e_date) {
              item.e_date = this.unixToDate(e_date);
            }
          }

          this.dataSource = data;
          this.totalCount = meta.page.total;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    onViewDetail(event) {
      const url = `/pages/services-tools/job-relocation/view?id=${event.key}`;
      this.$router.push(url);
    },
    onDelete(event) {
      //
    },
  },
};
</script>

<style></style>
